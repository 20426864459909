<template lang="pug">
section.row.row-bp-xs-up-half.align-center.gap(:class="additionalClasses")
  div
    h2 Learn More
    p
      | If you’d like to learn more about growing your business with people that truly care,
      | <strong>get ready to drop anchor.</strong>
    <router-link class="button" to="/contact">Contact Us</router-link>
  div
    vue-image(:width="936" :height="622" :source="require('@/assets/anchor.jpg')" alt="Anchor Logo")
</template>

<script>
export default {
  props: {
    additionalClasses: {
      type: Array
    }
  }
}
</script>
